import { FC } from "react";
import { Button } from "@/components/Button/Button";
import { Footer } from "@/components/Footer/Footer";
import { Header } from "@/components/Header/Header";
import { Section } from "@/components/Html/Section";
import { Link } from "@/components/Link/Link";
import { pagesPath } from "@/lib/$path";

export const Custom404: FC = () => {
  return (
    <Section>
      <Header />
      <div className="flex min-h-[calc(100vh-89.5px)] flex-col items-center justify-center text-center md:min-h-[calc(100vh-104.5px)]">
        <div className="kdot-container flex flex-col items-center justify-center">
          <h1 className="mb-[24px] text-[57px] font-normal md:mb-0 md:text-[128px] md:font-bold">404</h1>
          <h2 className="text-[22px] font-normal md:mb-[24px] md:text-[45px]">お探しのページは見つかりませんでした</h2>
          <h3 className="mb-[24px] text-[16px] font-normal">お手数ですが、トップページからページをお探しください。</h3>
          <Link href={pagesPath.$url()}>
            <Button className="text-[16px]">トップページへ</Button>
          </Link>
        </div>
      </div>
      <Footer />
    </Section>
  );
};
