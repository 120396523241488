import clsx from "clsx";
import { ComponentProps, FC } from "react";
import { TestIdProps } from "@/test/testId/testIds";

interface Props extends ComponentProps<"button">, TestIdProps {}

export const Button: FC<Props> = ({ className, disabled, testId, ...props }) => {
  return (
    <button
      data-testid={testId}
      className={clsx(
        className,
        "rounded-full border border-solid border-gray px-[30px] py-[10px] text-white",
        disabled ? "bg-gray" : "bg-primary",
      )}
      disabled={disabled}
      {...props}
    />
  );
};
